import React, { useState, useEffect, useCallback } from "react";
import { Grid, Paper, makeStyles, Container, Typography, Box, createStyles, Theme, Backdrop, Fade, TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Select, MenuItem, InputLabel, withStyles, InputBase, Button, Snackbar, IconButton } from "@material-ui/core";
import { getUser, getToken } from '../../services/auth';
import api from '../../services/api';
import Skeleton from "@material-ui/lab/Skeleton";
import Modal from '@material-ui/core/Modal';
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import MaterialTable from 'material-table'
import { datetimeToJavascript } from '../../utils/DateUtils';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { datetimeToHumanBrDate } from '../../utils/DateUtils';
import moment from "moment-timezone";
import MomentUtils from "@date-io/moment";
import "moment/locale/pt-br";
import CurrencyTextField from '@unicef/material-ui-currency-textfield';

// Icons
import { Delete, Error, Save, Cancel, AddCircle } from '@material-ui/icons';
import { User } from "../../interfaces";

const useStyles = makeStyles((theme: Theme) => createStyles({
	table: {},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		maxWidth: '600px',
		margin: 'auto',
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[4],
		padding: theme.spacing(2, 4, 3),
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	  },
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	margin: {
		margin: theme.spacing(1),
	},
	groupButtons: {
		'& > *': {
			marginRight: theme.spacing(2),
		},
	},
	m0: {
		margin: '0px',
	},
	p0: {
		padding: '0px',
	},
	alignCenter: {
		textAlign: 'center'
	}
}));

const BootstrapInput = withStyles((theme: Theme) =>
	createStyles({
		root: {
			'label + &': {
				marginTop: theme.spacing(3),
			},
		},
		input: {
			borderRadius: 4,
			position: 'relative',
			backgroundColor: theme.palette.background.paper,
			border: '1px solid #ced4da',
			fontSize: 16,
			padding: '17.5px 14px 17.5px 14px',
			transition: theme.transitions.create(['border-color', 'box-shadow']),
			// Use the system font instead of the default Roboto font.
			fontFamily: [
				'-apple-system',
				'BlinkMacSystemFont',
				'"Segoe UI"',
				'Roboto',
				'"Helvetica Neue"',
				'Arial',
				'sans-serif',
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(','),
			'&:focus': {
				borderRadius: 4,
				borderColor: '#80bdff',
				boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
			},
		},
	}),
)(InputBase);

const Transactions = (user: User) => {
	// USER
	// const user = JSON.parse(getUser() || "{}");

	// Style
	const classes = useStyles();

	// Transactions, Categories & Info
	const [transactions, setTransactions] = useState([]);
	const [isLoadingTransactions, setIsLoadingTransactions] = useState(false);
	const [isErrorLoadingTransactions, setIsErrorLoadingTransactions] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [error, setError] = useState("");
	const [categories, setCategories] = useState([]);
	const [accounts, setAccounts] = useState([]);

	// Lançamento
	const [id, setId] = useState(0);
	const [operationType, setOperationType] = useState("");
	const [description, setDescription] = useState("");
	const [datetime, setDatetime] = useState<Date>(new Date());
	const [amount, setAmount] = useState();
	const [type, setType] = useState(1);
	const [category, setCategory] = useState("");
	const [account, setAccount] = useState("");

	// Others
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarText, setSnackbarText] = useState("");
	const locale = "pt-br";
	const [selectedFilterDate, setSelectedFilterDate] = useState(new Date());

	const handleModalClose = () => setModalOpen(false);

	const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === 'clickaway') {
		  return;
		}
	
		setSnackbarOpen(false);
	};

	const handleCreateTransaction = async e => {
		e.preventDefault();
		if (!description || !datetime || !amount || !type || !category || !account){
			setError("Preencha todos os dados para criar este lançamento");
		} else {
			try {
				datetime.setUTCHours(0);
				datetime.setUTCMinutes(0);
				datetime.setUTCSeconds(0);

				await api.post("/transactions/store", {
					'typeID': type,
					'description': description,
					'datetime': datetime.toISOString().slice(0, 19).replace('T', ' '),
					'amount': amount,
					'categoryID': category,
					'accountID': account
				});
				handleModalClose();
				fetchTransactions();
				setSnackbarOpen(true);
				setSnackbarText("Lançamento criado!");
			} catch (e) {
				setError("Ocorreu um erro ao criar este lançamento");
			}
		}
	};

	const handleEditTransaction = async e => {
		e.preventDefault();
		if (!description || !datetime || !amount || !type || !category || !account){
			setError("Preencha todos os dados para editar este lançamento");
		} else {
			try {
				await api.post("/transactions/update", {
					'id': id,
					'typeID': type,
					'description': description,
					'datetime': datetime.toISOString().slice(0, 19).replace('T', ' '),
					'amount': amount,
					'categoryID': category,
					'accountID': account
				});
				handleModalClose();
				fetchTransactions();
				setSnackbarOpen(true);
				setSnackbarText("Lançamento atualizado!");
			} catch (e) {
				setError("Ocorreu um erro ao editar este lançamento");
			}
		}
	};

	const handleDeleteTransaction = async e => {
		if (id !== 0){
			try {
				await api.post("/transactions/destroy", {
					'id': id,
					'user': getUser(),
					'token': getToken()
				});
				handleModalClose();
				fetchTransactions();
				setSnackbarOpen(true);
				setSnackbarText("Lançamento removido!");
			} catch (e) {
				setError("Ocorreu um erro ao remover este lançamento.");
			}
		}
	};

	const newTransaction = () => {
		setOperationType("new");
		setDescription("");
		setDatetime(new Date());
		// setAmount();
		setType(1);
		setCategory("");
		setAccount("");
		setModalOpen(true);
	};

	const editTransaction = (transaction) => {
		setOperationType("edit");
		setId(transaction.id);
		setDescription(transaction.description);
		setDatetime(datetimeToJavascript(transaction.datetime));
		setAmount(transaction.amount);
		setType(transaction.type.id);
		setCategory(transaction.category.id);
		setAccount(transaction.account.id);
		setModalOpen(true);
	};

	const fetchTransactions = useCallback(async () => {
		setIsLoadingTransactions(true);
		setIsErrorLoadingTransactions(false);

		try{
			if (getUser()){
				const response = await api.post("transactions/all", {
					'userToken': getToken(),
					'userID': user.id,
					'date': selectedFilterDate.toISOString().slice(0, 19).replace('T', ' ')
				});
				const data = await response.data;
			
				setTransactions(data);
				setIsLoadingTransactions(false);
			}
		} catch (err) {
			setIsErrorLoadingTransactions(true);
		}
	}, [selectedFilterDate, user.id]);

	const handleSelectedFilterDate = useCallback((selectedDate: Date) => {
		if (selectedDate !== selectedFilterDate){
			selectedDate.setDate(1);
			setSelectedFilterDate(selectedDate);
			fetchTransactions();
		}
	}, [fetchTransactions, selectedFilterDate]);


	useEffect(() => {
		const fetchAccounts = async () => {
			try {
				if(getUser()){
					const response = await api.post("accounts/all", {
						'userToken': getToken(),
						'userID': user.id
					});
					const data = await response.data;
					setAccounts(data);
				}
				
			} catch (err) {
	
			}
		};

		const fetchCategories = async () => {
			try {
				// const user = JSON.parse(getUser() || "{id: 0}");
				const response = await api.post("categories/all", {
					'userToken': getToken(),
					'userID': user.id
				});
				const data = await response.data;
				setCategories(data);
			} catch (err) {
				
			}
		};

		fetchTransactions();
		fetchCategories();
		fetchAccounts();
		handleSelectedFilterDate(selectedFilterDate)
	}, [fetchTransactions, handleSelectedFilterDate, selectedFilterDate, user]);

	return (
		<Container maxWidth="lg">
			<Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
				<Alert onClose={handleSnackbarClose} severity="success" variant={"filled"}>
					{snackbarText}
				</Alert>
			</Snackbar>

			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={modalOpen}
				onClose={handleModalClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500
				}}
			>
				<Fade
					in={modalOpen}
				>
					<div className={classes.paper}>
						<form 
							onSubmit={(operationType === 'edit' ? handleEditTransaction : handleCreateTransaction)} 
							noValidate 
							autoComplete="off"
						>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Typography variant="h5" component="h5">
										{ operationType === 'edit' ? 'Editar lançamento' : 'Novo Lançamento'}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									{error && 
									<Alert severity="error" variant="filled">
										<AlertTitle>Erro!</AlertTitle>
										<strong>{error}</strong>
									</Alert>}
								</Grid>
								<Grid item xs={12}>
									<FormControl component="fieldset">
										<FormLabel component="legend">Tipo</FormLabel>
										<RadioGroup aria-label="type" name="type" value={type} onChange={e => setType(parseFloat(e.target.value))}>
											<FormControlLabel value={1} control={<Radio />} label="Despesa" />
											<FormControlLabel value={2} control={<Radio />} label="Receita" />
										</RadioGroup>
									</FormControl>
								</Grid>
								<Grid item xs={12}>
									<TextField 
										label="Descrição"
										placeholder="Digite a descrição"
										variant="outlined"
										fullWidth
										required
										value={description}
										onChange={e => setDescription(e.target.value)}
									/>
								</Grid>
								<Grid item xs={6}>
									<MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={locale}>
										<DatePicker
											inputVariant="outlined"
											margin="normal"
											id="date-picker-dialog"
											label="Data"
											value={datetime}
											format="DD/MM/yyyy"
											onChange={date => (date !== null ? setDatetime(date.toDate()) : (new Date()))}
											fullWidth
										/>
									</MuiPickersUtilsProvider>
								</Grid>
								<Grid item xs={6}>
									{/* <TextField 
										label="Valor"
										placeholder="Digite o valor"
										variant="outlined"
										fullWidth
										required
										value={amount}
										onChange={e => setAmount(Number(e.target.value))}
									/> */}
									<CurrencyTextField
										label="Valor"
										variant="outlined"
										fullWidth
										required
										value={amount}
										currencySymbol="R$"
										outputFormat="string"
										// onChange={e => setAmount(Number(e.target.value))}
										onChange={(event, value)=> setAmount(value)}
										decimalCharacter=","
										digitGroupSeparator="."
									/>
								</Grid>
								<Grid item xs={6}>
									<FormControl fullWidth>
										<InputLabel shrink id="selectCategory-label">Categoria</InputLabel>
										<Select
											labelId="selectCategory-label"
											id="selectCategory"
											value={category}
											onChange={e => setCategory(e.target.value as string)}
											input={<BootstrapInput />}
										>
											{categories.map((category) => (
												<MenuItem key={category['id']} value={category['id']}>{category['name']}</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={6}>
									<FormControl fullWidth>
										<InputLabel shrink id="selectAccount-label">Conta</InputLabel>
										<Select
											labelId="selectAccount-label"
											id="selectAccount"
											value={account}
											onChange={e => setAccount(e.target.value as string)}
											input={<BootstrapInput />}
										>
											{accounts.map((account) => (
												<MenuItem key={account['id']} value={account['id']}>{account['name']}</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12}>
									<div className={classes.groupButtons}>
										{(operationType === 'edit' ? 
											<Button variant="outlined" color="default" onClick={handleDeleteTransaction} startIcon={<Delete />}>Remover</Button>
											: ''
										)}
										<Button variant="outlined" color="secondary" onClick={handleModalClose} startIcon={<Cancel />}>Cancelar</Button>
										<Button variant="outlined" color="primary" type="submit" startIcon={<Save />}>Salvar</Button>
									</div>
								</Grid>
							</Grid>
						</form>
					</div>
				</Fade>
			</Modal>

			<Box component={Paper} p={2}>
				<Grid container spacing={2} >
					<Grid item xs={12} >
						<Grid container spacing={2} alignContent={'center'} alignItems={'center'} justify={'center'}>
							<Grid item xs={6} lg={4} md={4}>
								<Typography>
									<b>Lançamentos</b>
									<IconButton aria-label="add" onClick={newTransaction}>
										<AddCircle fontSize="large"/>
									</IconButton>
								</Typography>
							</Grid>
							<Grid item xs={6} lg={4} md={4} className={classes.alignCenter}>
								<MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={locale}>
									<DatePicker
										clearable
										value={selectedFilterDate}
										onChange={month => (month !== null ? handleSelectedFilterDate(month.toDate()) : (new Date()))}
										views={["month", "year"]}
										format="MMMM - YYYY"
										disablePast={false}
										disableFuture={false}
										minDate={new Date(1990, 1, 1)}
										maxDate={new Date(2060, 12, 31)}
										label={"Mês selecionado"}
									/>
								</MuiPickersUtilsProvider>
							</Grid>
							<Grid item xs={12} lg={4} md={4}>
								<Typography align={"right"}>
									Lançamentos encontrados neste mês: { transactions.length }
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					{isErrorLoadingTransactions &&
						<Grid item xs={12}>
							<Alert variant="filled" severity="error">Erro ao carregar a lista de lançamentos. Tente novamente mais tarde.</Alert>
						</Grid>
					}
					{(!isErrorLoadingTransactions && isLoadingTransactions) && 
						<Grid item xs={12}>
							<Skeleton animation="wave" height={60} />
							<Skeleton animation="wave" height={60} />
							<Skeleton animation="wave" height={60} />
						</Grid>
					}
					{(!isErrorLoadingTransactions && !isLoadingTransactions) &&
						<Grid item xs={12}>
							{transactions.length === 0 ? (
								<Typography align="center">
									<Error fontSize="large" />
									<br></br>
									Nenhum lançamento encontrado para este período.
								</Typography>
							) : (
								<MaterialTable 
									title=""
									columns={[
										{ title: 'Data', field: 'datetimeHuman' },
										{ title: 'Descrição', field: 'description' },
										{ title: 'Conta', field: 'accountName' },
										{ title: 'Categoria', field: 'categoryName' },
										{ title: 'Valor', field: 'amountBr' },
										{ title: 'Tipo', field: 'typeName'}
									]}
									data={transactions.map((transaction) => (
										{
											'id': transaction['id'],
											'datetime': transaction['datetime'],
											'datetimeHuman': datetimeToHumanBrDate(transaction['datetime']),
											'description': transaction['description'],
											'account': transaction['account'],
											'accountName': transaction['account']['name'],
											'category': transaction['category'],
											'categoryName': transaction['category']['name'],
											'type': transaction['type'],
											'typeName': transaction['type']['name'],
											'amount': (parseFloat(transaction['amount']).toFixed(2)),
											'amountBr': "R$ " + (parseFloat(transaction['amount']).toFixed(2))
										}
									))}
									actions={[
										{
										  icon: 'edit',
										  tooltip: 'Editar lançamento',
										  onClick: (event, rowData) => editTransaction(rowData)
										}

									]}
									options={{
										sorting: false,
										pageSize: 10,
										search: false,
										padding: "dense",
										draggable: false
									}}
									localization={{
										pagination: {
											labelDisplayedRows: '{from}-{to} de {count}',
											labelRowsSelect: 'lançamentos',
											firstAriaLabel: 'Primeira página',
											firstTooltip: 'Primeira página',
											previousAriaLabel: 'Página anterior',
											previousTooltip: 'Página anterior',
											nextAriaLabel: 'Próxima página',
											nextTooltip: 'Próxima página',
											lastAriaLabel: 'Última página',
											lastTooltip: 'Última página'
										},
										toolbar: {
											nRowsSelected: '{0} lançamento(s) selecionado(s)',
											searchTooltip: 'Buscar',
											searchPlaceholder: 'Buscar'
										},
										header: {
											actions: 'Ações'
										},
										body: {
											emptyDataSourceMessage: 'Nenhum lançamento disponível',
											filterRow: {
												filterTooltip: 'Filtrar'
											}
										}
									}}
									components={{
										Container: props => <Paper {...props} elevation={0}/>
								   	}}
								/>
							)}
						</Grid>
					}
				</Grid>
			</Box>
		</Container>
	);
}

export default Transactions;