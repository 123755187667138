import React, { } from "react";
import { Box, Button, Container, Grid, Paper, Typography } from "@material-ui/core";
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CreateIcon from '@material-ui/icons/Create';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import { Animation, BarSeries } from "@devexpress/dx-react-chart";
import { ArgumentAxis, Chart, ValueAxis } from "@devexpress/dx-react-chart-material-ui";

const chartData = [
	{ mes: 'Janeiro', saldo: -510 },
	{ mes: 'Fevereiro', saldo: 52 },
	{ mes: 'Março', saldo: 430 },
	{ mes: 'Abril', saldo: 544 },
	{ mes: 'Maio', saldo: 402 },
	{ mes: 'Junho', saldo: 901 },
	{ mes: 'Julho', saldo: 1201 },
];

const Homepage = () => {
	return (
		<Grid container spacing={2}>
			<Container>
				<Grid container spacing={2} alignContent={'center'} alignItems={'center'} justify={'center'}>
					<Grid item xs={12} md={6} lg={6}>
						<Typography variant="h3" component="h3" gutterBottom>
							Suas finanças podem ser planejadas
						</Typography>
						<Typography variant="subtitle1" gutterBottom>
							Mais de 58% dos brasileiros não se dedicam às próprias finanças. Faça parte da mudança. Planeje suas finanças.
						</Typography>
						<Button variant="contained" color="secondary" href={"/login"}>
							Planejar minhas finanças
						</Button>
					</Grid>

					<Grid item xs={12} md={6} lg={6} >
						<Paper variant={'outlined'}>
							<Box p={2}>
								<Chart
									data={chartData}
								>
									<ArgumentAxis />
									<ValueAxis />

									<BarSeries
										valueField="saldo"
										argumentField="mes"
									/>
									<Animation />
								</Chart>
							</Box>
						</Paper>
					</Grid>
				</Grid>
			</Container>

			<Box m={'auto'} mt={4}>
				<Container>
					<Grid container spacing={2} >
						<Grid item xs={12} >
							<Typography variant="h3" component="h3" gutterBottom align={"center"}>
								Nossas principais funcionalidades
							</Typography>
						</Grid>

						<Grid item xs={12} md={4} lg={4}>
							<Paper variant={'outlined'}>
								<Box p={2}>
									<Typography variant="h4" component="h4" gutterBottom>
										<AccountBalanceIcon/> Contas financeiras
									</Typography>
									
									<Typography variant="subtitle1" gutterBottom>
										Você tem diversas contas financeiras? Usa várias instituições financeras? Não há problema. É possível criar diversas contas financeiras. 
									</Typography>
								</Box>
							</Paper>
						</Grid>

						<Grid item xs={12} md={4} lg={4}>
							<Paper variant={'outlined'}>
								<Box p={2}>
									<Typography variant="h4" component="h4" gutterBottom>
										<CreateIcon/> Lançamentos
									</Typography>
									
									<Typography variant="subtitle1" gutterBottom>
										Crie, edite e remova lançamentos de despesas e receitas. Vincule lançamentos e contas financeiras. Defina uma categoria e comente para acompanhamento.
									</Typography>
								</Box>
							</Paper>
						</Grid>

						<Grid item xs={12} md={4} lg={4}>
							<Paper variant={'outlined'}>
								<Box p={2}>
									<Typography variant="h4" component="h4" gutterBottom>
										<EqualizerIcon/> Relatórios
									</Typography>
									
									<Typography variant="subtitle1" gutterBottom>
										Veja relatórios gráficos da sua evolução financeira, de maneira simples e intuitiva.
									</Typography>
								</Box>
							</Paper>
						</Grid>
					</Grid>
				</Container>
			</Box>
		</Grid>
	);
}

export default Homepage;