import React, { useState, useEffect, useCallback } from "react";
import { Container, Typography, Grid, Box, Paper, makeStyles, TableContainer, TableRow, TableCell, TableBody, Table, TableHead, Tooltip } from "@material-ui/core";
import api from "../../services/api";
import { getToken } from "../../services/auth";
import { datetimeToHumanBrDate } from '../../utils/DateUtils';

// Icons
import { Error, Help } from '@material-ui/icons';
import Alert from "@material-ui/lab/Alert";
import Skeleton from "@material-ui/lab/Skeleton";
import {Chart,ArgumentAxis,ValueAxis,BarSeries,Legend} from '@devexpress/dx-react-chart-material-ui';
import { Stack, Animation, LineSeries} from '@devexpress/dx-react-chart';
import { User } from "../../interfaces";

const useStyles = makeStyles({
	table: {

	},
});

const Dashboard = (user: User) => {
	const [accounts, setAccounts] = useState([]);
	const [balanceAccounts, setBalanceAccounts] = useState(0.00);
	const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);
	const [isErrorLoadingAccounts, setIsErrorLoadingAccounts] = useState(false);
	const classes = useStyles();
	// const [user, setUser] = useState<User | null>({id: 0, name: "", email: "", created_at: ""});

	// IEM = Income X Expense Monthly
	const [reportIEM, setReportIEM] = useState([]);
	const months = 3;

	// Loading
	const [isLoadingReportIEM, setIsLoadingReportIEM] = useState(false);
	const [isErrorLoadingReportIEM, setIsErrorLoadingReportIEM] = useState(false);

	const fetchReportIncomeExpenseMonthly = useCallback(async () => {
		setIsLoadingReportIEM(true);
		setIsErrorLoadingReportIEM(false);

		try{
			const response = await api.post("reports/income-expenses/monthly/last", {
				'userToken': getToken(),
				'userID': user.id,
				'months': months
			});
			const data = await response.data;
			setReportIEM(data);
			setIsLoadingReportIEM(false);
		} catch (err) {
			setIsErrorLoadingReportIEM(true);
		}
	}, [months, user]);
	
	useEffect(() => {
		const fetchAccounts = async () => {
			setIsLoadingAccounts(true);
			setIsErrorLoadingAccounts(false);
	
			try{
				const response = await api.post("accounts/all", {
					'userToken': getToken(),
					'userID': user.id
				});
				const data = await response.data;
				setAccounts(data);
				setIsLoadingAccounts(false);
				let tempBalance = 0.0;
				data.forEach(account => {
					tempBalance += account.balance;
				});
				setBalanceAccounts(tempBalance);
			} catch (err) {
				setIsErrorLoadingAccounts(true);
			}
		};

		fetchAccounts();
		fetchReportIncomeExpenseMonthly();
	}, [user, fetchReportIncomeExpenseMonthly]);

	return (
		<Container maxWidth="md">
			{user ? (
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Typography variant="h5" component="h5">
							Olá, {user.name}!
						</Typography>
					</Grid>

					<Grid item xs={6}>
						<Box component={Paper} p={2}>
							<Tooltip title="Esta é a data que sua conta foi criada." arrow>
								<Typography variant="subtitle1" align={"center"}>
									Conosco desde <Help fontSize={"small"} />		
								</Typography>
							</Tooltip>
							<Typography variant="h5" component="h5" align={"center"}>
								{datetimeToHumanBrDate(user.createdAt)}
							</Typography>
						</Box>
					</Grid>

					<Grid item xs={6}>
						<Box component={Paper} p={2}>
							<Tooltip title="Este é o saldo unificado de suas contas." arrow>
								<Typography variant="subtitle1" align={"center"}>
									Saldo geral <Help fontSize={"small"} />								
								</Typography>
							</Tooltip>
							<Typography variant="h5" component="h5" align={"center"}>
								{isErrorLoadingAccounts && 
									<Alert variant="filled" severity="error">Erro ao carregar o saldo.</Alert>
								}
								{(!isErrorLoadingAccounts && isLoadingAccounts) && 
									<Skeleton animation="wave" height={32} />
								}
								{(!isErrorLoadingAccounts && !isLoadingAccounts) &&
									<div>
										{accounts.length === 0 ? (
											<div>Você não cadastrou nenhuma conta.</div>
										) : (
											"R$ " + balanceAccounts.toFixed(2)
										)}
									</div>
									
								}
							</Typography>
						</Box>
					</Grid>

					<Grid item xs={12}>
						<Box component={Paper} p={2}>
							<Typography variant="subtitle1">
								Suas contas
							</Typography>
							{isErrorLoadingAccounts && 
								<Grid item xs={12}>
									<Alert variant="filled" severity="error">Erro ao carregar as informações. Tente novamente mais tarde.</Alert>
								</Grid>
							}
							{(!isErrorLoadingAccounts && isLoadingAccounts) && 
								<Grid item xs={12}>
									<Paper>
										<Skeleton animation="wave" height={60} />
										<Skeleton animation="wave" height={60} />
										<Skeleton animation="wave" height={60} />
									</Paper>
								</Grid>
							}
							{(!isErrorLoadingAccounts && !isLoadingAccounts) && 
								<Grid item xs={12}>
									{accounts.length === 0 ? (
										<Typography align="center">
											<Error fontSize="large" />
											<br></br>
											Nenhuma conta cadastrada.
										</Typography>
									) : (
										<TableContainer component={Paper}>
											<Table 
												className={classes.table} 
												size="small" 
												aria-label="a dense table"
											>
												<TableHead>
													<TableRow>
														<TableCell align="center">Nome da conta</TableCell>
														<TableCell align="center">Saldo da conta</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{accounts.map((account) => (
														<TableRow hover key={account['name']}>
															<TableCell align="center" component="th" scope="row">
																{account['name']}
															</TableCell>
															<TableCell align="center">R$ { parseFloat(account['balance']).toFixed(2) }</TableCell>
														</TableRow>
													))}
												</TableBody>
											</Table>
										</TableContainer>
									)}	
								</Grid>
							}
						</Box>
					</Grid>

					<Grid item xs={12}>
						{isErrorLoadingReportIEM && 
							<Grid item xs={12}>
								<Alert variant="filled" severity="error">Erro ao carregar este relatório. Tente novamente mais tarde.</Alert>
							</Grid>
						}
						{(!isErrorLoadingReportIEM && isLoadingReportIEM) && 
							<Grid item xs={12}>
								<Paper>
									<Skeleton animation="wave" height={400} />
								</Paper>
							</Grid>
						}
						{(!isErrorLoadingReportIEM && !isLoadingReportIEM) &&
							<div>
								{reportIEM && reportIEM.length === 0 ? (
									<Typography align="center">
										<Error fontSize="large" />
										<br></br>
										Nenhum relatório encontrado.
									</Typography>
								) : (
									<Box component={Paper} p={2}>
										<Grid container spacing={3}>			
											<Grid item xs={12}>
												<Typography>
													{ "Receitas e Despesas nos últimos " + months + " meses." }
												</Typography>
											</Grid>
											<Grid item xs={12}>
												<Chart
													data={reportIEM}
												>
													<ArgumentAxis />
													<ValueAxis />

													<BarSeries
														name="Receita"
														valueField="income"
														argumentField="yearmonth"
														color="#2e7d32"
													/>
													<BarSeries
														name="Despesa"
														valueField="expense"
														argumentField="yearmonth"
														color="#c62828"
													/>

													<LineSeries
														name="Saldo"
														valueField="balance"
														argumentField="yearmonth" 
														color="#ffffff"
													/>

													<Animation />
													<Legend position="bottom" />
													<Stack />
												</Chart>
											</Grid>
										</Grid>
									</Box>
								)} 
							</div>
						}
					</Grid>
				</Grid>
			) : (
				<div>Erro</div>
			)}
		</Container>
	);
};

export default Dashboard;