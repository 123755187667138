import React from "react";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Settings from "./pages/Settings";
import Homepage from "./pages/Homepage";
import Dashboard from "./pages/Dashboard";
import ReportsPage from "./pages/Reports";
import TransactionsPage from "./pages/Transactions";
import AccountsPage from "./pages/Accounts";
import { Route, Switch, Redirect } from "react-router-dom";
import { User } from "./interfaces";

const PrivateRoute = ({ component: Component, user, ...rest }) => (
	<Route {...rest} {...user} render={(props) => (
		user !== undefined && user.id !== 0
			? <Component {...user}/>
			: <Redirect to="/Login"/>
	)} />
);

const Routes = (user: User) => (
	<Switch>
		<Route exact path="/" component={Homepage} />
		<Route path="/login" component={SignIn} {...user} />
		<Route path="/register" component={SignUp} {...user} />
		<PrivateRoute path="/settings" component={Settings} user={user} />
		<PrivateRoute path="/dashboard" component={Dashboard} user={user} />
		<PrivateRoute path="/reports" component={ReportsPage} user={user} />
		<PrivateRoute path="/accounts" component={AccountsPage} user={user} />
		<PrivateRoute path="/transactions" component={TransactionsPage} user={user} />

		<Route path="*" component={() => <h1>Erro 404 - Página não encontrada. =(</h1>} />
	</Switch>
);

export default Routes;