import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Link from '@material-ui/core/Link';
import api from "../../services/api";
import { login, getUser } from "../../services/auth";
import { Typography, Button, TextField, Container, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { User } from "../../interfaces";

const SignIn = (user: User) => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState("");
	const history = useHistory();

	// Verificar se a pessoa está logada
	if (getUser().id !== 0){
		history.push("/dashboard");
		window.location.reload();
	}
  
	const handleSignIn = async e => {
		e.preventDefault();
		if (!email || !password) {
			setError("Preencha e-mail e senha para continuar!");
		} else {
			try {
				const response = await api.post("/auth/login", { email, password });
				login(response.data.token, response.data.user);
				history.push("/dashboard");
				window.location.reload();
			} catch (err) {
				setError("Houve um problema com o login, verifique suas credenciais.");
			}
		}
	};

	return (
		<Container maxWidth="sm">
			<form
				onSubmit={handleSignIn} 
				noValidate 
				autoComplete="off"
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant="h4" gutterBottom color={"textPrimary"} align="center">
							Login
						</Typography>
					</Grid>

					<Grid item xs={12}>
						{ 
							error && 
						<Alert severity="error" variant="filled">
							<AlertTitle>Erro!</AlertTitle>
							<strong>{error}</strong>
						</Alert>
						}  
					</Grid>
	
					<Grid item xs={12}>
						<TextField
							margin="normal" 
							label="E-mail"
							placeholder="Digite seu e-mail"
							variant="outlined"
							fullWidth
							required
							autoFocus
							onChange={e => setEmail(e.target.value)}
						/>
					</Grid>
		
					<Grid item xs={12}>
						<TextField 
							margin="normal" 
							label="Senha"
							placeholder="Digite sua senha"
							variant="outlined"
							fullWidth
							type="password"
							required
							onChange={e => setPassword(e.target.value)}
						/>
					</Grid>
		
					<Grid item xs={12}>
						<Button
							variant="contained" 
							color="primary" 
							type="submit" 
							fullWidth
						>
							Entrar
						</Button>
					</Grid>

					<Grid item xs={12}>
						<Typography align="right" color="primary">
							<Link href="/register">
								Não tem uma conta? Clique para criar.
							</Link>
						</Typography>
					</Grid>
				</Grid>
			</form>
		</Container>
	);
};

export default SignIn;