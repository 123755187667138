import React, { useState, MouseEvent } from 'react';
import clsx from 'clsx';
import { Link, useHistory } from "react-router-dom";
import MaterialLink from '@material-ui/core/Link';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { Button, Drawer } from '@material-ui/core';
import { logout } from '../../services/auth';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ListIcon from '@material-ui/icons/List';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { User } from '../../interfaces';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => createStyles({
	root: { display: 'flex', },
	title: {
		flexGrow: 1
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: 36,
	},
	hide: {
		display: 'none',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create('width', {
		  easing: theme.transitions.easing.sharp,
		  duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: theme.spacing(7) + 1,
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(9) + 1,
		},
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
}));

const HeaderAuth = (user: User) => {
	const classes = useStyles();
	const [auth] = useState(true);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleMenu = (event: MouseEvent<HTMLElement>) => { setAnchorEl(event.currentTarget); };
	const handleClose = () => { setAnchorEl(null); };
	const history = useHistory();
	const [openDrawer, setOpenDrawer] = useState(false);

	const handleDrawerOpen = () => {
		setOpenDrawer(true);
	};
	
	const handleDrawerClose = () => {
		setOpenDrawer(false);
	};

	const handleDrawerToggle = () => {
		setOpenDrawer(!openDrawer);
	};

	function handleLogout() {
		logout();
		history.push('/login');
		window.location.reload();
	};

	return (
		<div>
			<AppBar
				position="fixed"
				className={clsx(classes.appBar, {
					[classes.appBarShift]: openDrawer,
				})}
			>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawerToggle}
						edge="start"
						className={clsx(classes.menuButton, {
							[classes.hide]: open,
						})}
					>
						<MenuIcon />
					</IconButton>
					<Typography variant="h6" className={classes.title}>
						<MaterialLink href={"/dashboard"} color="secondary">
							TCC
						</MaterialLink>
					</Typography>
					{auth && (
						<div>
							<Button
								aria-label="account of current user"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={handleMenu}
								color="inherit"
								endIcon={<AccountCircle />}
							>
								Meu perfil
							</Button>

							<Menu
								id="menu-appbar"
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								open={open}
								onClose={handleClose}
							>
								<div>
									<MenuItem disabled>
										<ListItemIcon>
											<AccountCircle fontSize="small" />
										</ListItemIcon>
										{ user.name }
									</MenuItem>

									<MenuItem component={Link} to='/settings'>
										<ListItemIcon>
											<LockOpenIcon fontSize="small" />
										</ListItemIcon>
										Configurações
									</MenuItem>
								
									<MenuItem component={Link} to='/dashboard'>
										<ListItemIcon>
											<LockOpenIcon fontSize="small" />
										</ListItemIcon>
										Meu painel
									</MenuItem>
									
									<MenuItem onClick={handleLogout}>
										<ListItemIcon>
											<LockOpenIcon fontSize="small" />
										</ListItemIcon>
										Sair
									</MenuItem>
								</div>
							</Menu>
						</div>
					)}
				</Toolbar>
			</AppBar>
			<Drawer
				variant="permanent"
				className={clsx(classes.drawer, {
					[classes.drawerOpen]: openDrawer,
					[classes.drawerClose]: !openDrawer,
				})}
				classes={{
					paper: clsx({
						[classes.drawerOpen]: openDrawer,
						[classes.drawerClose]: !openDrawer,
					}),
				}}
			>
				<div className={classes.toolbar}>
					<IconButton onClick={handleDrawerToggle}>
						<ChevronLeftIcon />
					</IconButton>
				</div>
				<Divider />
				<List>
					<ListItem
						component={Link}
						to='/dashboard'
						button
						key="Resumo"
					>
						<ListItemIcon><DashboardIcon /></ListItemIcon>
						<ListItemText primary="Resumo" />
					</ListItem>
					<ListItem
						component={Link}
						to='/accounts'
						button
						key="Contas"
					>
						<ListItemIcon><AccountBalanceIcon /></ListItemIcon>
						<ListItemText primary="Contas" />
					</ListItem>
					<ListItem
						component={Link}
						to='/transactions'
						button
						key="Lançamentos"
					>
						<ListItemIcon><ListIcon /></ListItemIcon>
						<ListItemText primary="Lançamentos" />
					</ListItem>
				</List>
				<Divider />
				<List>
					<ListItem 
						component={Link}
						to='/reports'
						button 
						key="Relatórios"
					>
						<ListItemIcon><AssessmentIcon /></ListItemIcon>
						<ListItemText primary="Relatórios" />
					</ListItem>
				</List>
			</Drawer>
		</div>
	);
};

export default HeaderAuth;
