import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Link from '@material-ui/core/Link';
import api from "../../services/api";
import { Typography, Container, Grid, TextField, Button } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { login, getUser } from "../../services/auth";
import { cpfMask } from '../../utils/Mask';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment-timezone";
import MomentUtils from "@date-io/moment";
import "moment/locale/pt-br";
import { cpf as cpfValidator } from 'cpf-cnpj-validator'; 

const SignUp = () => {
	const locale = "pt-br";
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [passwordConfirmation, setPasswordConfirmation] = useState("");
	const [cpf, setCpf] = useState("");
	const [birthday, setBirthday] = useState(new Date());
	const [error, setError] = useState("");
	const [errorArray, setErrorArray] = useState([]);
	const history = useHistory();

	// Verificar se a pessoa está logada
	if (getUser().id !== 0){
		history.push("/dashboard");
		window.location.reload();
	}

	const handleSignUp = async e => {
		e.preventDefault();
		console.log(cpfValidator.isValid(cpf.replace('.', '').replace('-', '')));
		if (!name || !email || !password || !passwordConfirmation || !cpf || !birthday) {
			setError("Preencha todos os dados para se cadastrar.");
			setErrorArray([]);
		} 
		else if (password !== passwordConfirmation){
			setError("A confirmação da senha deve ser igual a senha.");
			setErrorArray([]);
		} else if (cpfValidator.isValid(cpf.replace('.', '').replace('-', '')) === false) {
			setError("O CPF digitado é inválido.");
			setErrorArray([]);
		} else {
			try {
				await api.post("/auth/register", { 
					'name': name, 
					'email': email, 
					'password': password, 
					'password_confirmation': passwordConfirmation,
					'cpf': cpf,
					'birthday': birthday.toISOString().slice(0, 19).replace('T', ' ') 
				}).then(response => {
					login(response.data.token, response.data.user);
					history.push("/dashboard");
					window.location.reload();
				}).catch(error => {
					setError("Os seguintes erros ocorram ao registrar sua conta: ");
					setErrorArray(error.response.data);
					console.log(error.response.data);
				});
			} catch (err) {
				setError("Ocorreu um erro ao registrar sua conta." + err);
			}
		}
	};
	return (
		<Container maxWidth="sm">
			<form 
				onSubmit={handleSignUp} 
				noValidate 
				autoComplete="off"
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant="h4" gutterBottom color={"textPrimary"} align="center">
							Registro
						</Typography>
					</Grid>

					<Grid item xs={12}>
						{error && 
						<Alert severity="error" variant="filled">
							<AlertTitle>Erro!</AlertTitle>
							<strong>{error}</strong>
							{errorArray.length !== 0 ? (
								<Typography>
									{Object.keys(errorArray).map(key => 
										<Typography>
											Campo {key}: { errorArray[key] }
										</Typography>
									)}
								</Typography>
							) : (
								''
							)}
						</Alert>}
					</Grid>
					
					<Grid item xs={12}>
						<TextField 
							label="Nome completo"
							value={name}
							placeholder="Digite seu nome completo"
							variant="outlined"
							fullWidth
							required
							onChange={e => setName(e.target.value)}
						/>
					</Grid>

					<Grid item xs={12}>
						<TextField 
							label="E-mail"
							value={email}
							placeholder="Digite seu e-mail"
							variant="outlined"
							fullWidth
							required
							onChange={e => setEmail(e.target.value)}
						/>
					</Grid>

					<Grid item xs={12}>
						<TextField 
							label="CPF"
							value={cpf}
							placeholder="Digite seu CPF"
							variant="outlined"
							fullWidth
							required
							onChange={e => setCpf(cpfMask(e.target.value))}
						/>
					</Grid>

					<Grid item xs={12}>
						<MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={locale}>
							<DatePicker
								fullWidth
								inputVariant="outlined"
								clearable
								value={birthday}
								onChange={date => (date !== null ? setBirthday(date.toDate()) : (new Date()))}
								format="DD/MM/YYYY"
								disablePast={false}
								disableFuture={true}
								minDate={new Date(1900, 1, 1)}
								maxDate={new Date()}
								label={"Data de nascimento"}
							/>
						</MuiPickersUtilsProvider>

					</Grid>

					<Grid item xs={12}>
						<TextField 
							label="Senha"
							placeholder="Digite sua senha"
							variant="outlined"
							fullWidth
							type="password"
							required
							onChange={e => setPassword(e.target.value)}
						/>
					</Grid>

					<Grid item xs={12}>
						<TextField 
							label="Confirmação de senha"
							placeholder="Digite sua senha novamente"
							variant="outlined"
							fullWidth
							type="password"
							required
							onChange={e => setPasswordConfirmation(e.target.value)}
						/>
					</Grid>

					<Grid item xs={12}>
						<Button variant="contained" color="primary" type="submit" fullWidth>Cadastrar</Button>
					</Grid>
					<Grid item xs={12}>
						<Typography align="right">
							<Link href="/login">
								Já tem uma conta? Clique para entrar.
							</Link>
						</Typography>
					</Grid>
				</Grid>
			</form>
		</Container>
	);
};

export default SignUp;