import React, {  } from "react";
import { Grid, Paper, Typography, Box, Divider, Container } from "@material-ui/core";
import { User } from "../../interfaces";
import { datetimeToHumanBrDate } from '../../utils/DateUtils';

const Settings = (user: User) => {
	return (
		<Container maxWidth="md">
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Box component={Paper} p={2}>
						<Grid container spacing={2}>
							<Grid item xs={12}>	
								<Typography variant={"h5"} align={'center'}>
									Configurações
								</Typography>
								<Grid container spacing={2}>
									<Grid item xs={6}>
										<Typography variant={"body1"}>
											Nome
										</Typography>
										<Typography variant={"h5"}>
											{ user.name }
										</Typography>
									</Grid>
									<Grid item xs={6}>
										<Typography variant={"body1"}>
											E-mail
										</Typography>
										<Typography variant={"h5"}>
											{ user.email }
										</Typography>
									</Grid>
									<Grid item xs={6}>
										<Typography variant={"body1"}>
											Data de nascimento
										</Typography>
										<Typography variant={"h5"}>
											{ user.birthday }
										</Typography>
									</Grid>
									<Grid item xs={6}>
										<Typography variant={"body1"}>
											CPF
										</Typography>
										<Typography variant={"h5"}>
											{ user.cpf }
										</Typography>
									</Grid>
									<Grid item xs={6}>
										<Typography variant={"body1"}>
											Data de registro
										</Typography>
										<Typography variant={"h5"}>
											{ datetimeToHumanBrDate(user.createdAt) }
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Divider light />
									</Grid>
									{/* <Grid item xs={12}>
										Fazer download dos dados
									</Grid>
									<Grid item xs={12}>
										Excluir a minha conta
									</Grid> */}
								</Grid>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Grid>
		</Container>
	);
}

export default Settings;