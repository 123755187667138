import React, { useState, useCallback, useEffect } from "react";
import Paper from '@material-ui/core/Paper';
import { Grid, Typography, FormControl, InputLabel, Select, MenuItem, Container, Divider, TableHead, TableRow, TableCell, TableBody, Table, Tab, Tabs } from "@material-ui/core";
import api from "../../services/api";
import { getToken } from "../../services/auth";
import {Chart,ArgumentAxis,ValueAxis,BarSeries,Legend} from '@devexpress/dx-react-chart-material-ui';
import { Stack, Animation, LineSeries} from '@devexpress/dx-react-chart';
import Alert from "@material-ui/lab/Alert";
import Skeleton from "@material-ui/lab/Skeleton";

// Icons
import { Error } from '@material-ui/icons';
import { User } from "../../interfaces";


const Reports = (user: User) => {
	const [tabValue, setTabValue] = useState(0);

	// IEM = Income X Expense Monthly
	const [reportIEM, setReportIEM] = useState([]);
	const [months, setMonths] = useState(3);

	// Loading
	const [isLoadingReportIEM, setIsLoadingReportIEM] = useState(false);
	const [isErrorLoadingReportIEM, setIsErrorLoadingReportIEM] = useState(false);


	const fetchReportIncomeExpenseMonthly = useCallback(async () => {
		setIsLoadingReportIEM(true);
		setIsErrorLoadingReportIEM(false);

		try{
			// const user = JSON.parse(getUser() || "{}");
			const response = await api.post("reports/income-expenses/monthly/last", {
				'userToken': getToken(),
				'userID': user?.id,
				'months': months
			});
			const data = await response.data;
			setReportIEM(data);
			setIsLoadingReportIEM(false);
		} catch (err) {
			setIsErrorLoadingReportIEM(true);
		}
	}, [months, user]);

	useEffect(() => {
		fetchReportIncomeExpenseMonthly();
	}, [fetchReportIncomeExpenseMonthly]);

	return (
		<Container>
			<Paper>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Grid spacing={3}>
							<Grid item xs={12}>
								<Typography variant={"h5"} align={"center"}>Relatórios</Typography>
								<Tabs
									value={tabValue}
									indicatorColor="primary"
									textColor="primary"
									centered
									onChange={(e, newValue) => setTabValue(newValue)}
								>
									<Tab label="Receitas e Despesas" />
								</Tabs>
							</Grid>
						</Grid>
						<Divider light />
						{(tabValue === 0 && 
							<div>
								{isErrorLoadingReportIEM && 
									<Grid item xs={12}>
										<Alert variant="filled" severity="error">Erro ao carregar este relatório. Tente novamente mais tarde.</Alert>
									</Grid>
								}
								{(!isErrorLoadingReportIEM && isLoadingReportIEM) && 
									<Grid item xs={12}>
										<Paper>
											<Skeleton animation="wave" height={400} />
										</Paper>
									</Grid>
								}
								{(!isErrorLoadingReportIEM && !isLoadingReportIEM) &&
									<div>
										{reportIEM && reportIEM.length === 0 ? (
											<Typography align="center">
												<Error fontSize="large" />
												<br></br>
												Nenhum relatório encontrado.
											</Typography>
										) : (
											<Grid container spacing={3} style={{padding: '16px'}}>
												<Grid item xs={12}>
													<Typography>
														{ "Receitas e Despesas nos últimos " + months + " meses." }
													</Typography>
													<FormControl>
														<InputLabel shrink id="demo-simple-select-placeholder-label-label">
														Período
														</InputLabel>
														<Select
															labelId="demo-simple-select-placeholder-label-label"
															id="demo-simple-select-placeholder-label"
															value={months}
															onChange={e => setMonths(e.target.value as number)}
															displayEmpty
														>
															<MenuItem value={3}>3 meses</MenuItem>
															<MenuItem value={6}>6 meses</MenuItem>
															<MenuItem value={12}>12 meses</MenuItem>
															<MenuItem value={18}>18 meses</MenuItem>
															<MenuItem value={24}>24 meses</MenuItem>
															<MenuItem value={30}>30 meses</MenuItem>
														</Select>
													</FormControl>
												</Grid>
												<Grid item xs={12}>
													<Chart
														data={reportIEM}
													>
														<ArgumentAxis />
														<ValueAxis />

														<BarSeries
															name="Receita"
															valueField="income"
															argumentField="yearmonth"
															color="#2e7d32"
														/>
														<BarSeries
															name="Despesa"
															valueField="expense"
															argumentField="yearmonth"
															color="#c62828"
														/>

														<LineSeries
															name="Saldo"
															valueField="balance"
															argumentField="yearmonth" 
															color="#ffffff"
														/>

														<Animation />
														<Legend position="bottom" />
														<Stack />
													</Chart>
													<Divider light />
												</Grid>

												<Grid item xs={12}>
													<Typography>
														Detalhes
													</Typography>

													<Table size="small" aria-label="a dense table">
														<TableHead>
															<TableRow>
																<TableCell>Período</TableCell>
																<TableCell align="right">Receita</TableCell>
																<TableCell align="right">Despesa</TableCell>
																<TableCell align="right">Saldo</TableCell>
															</TableRow>
														</TableHead>
														<TableBody>
															{reportIEM.map((report) => (
																<TableRow key={report['yearmonth']}>
																	<TableCell component="th" scope="row">
																		{report['yearmonth']}
																	</TableCell>
																	<TableCell align="right">R$ { parseFloat(report['income']).toFixed(2) }</TableCell>
																	<TableCell align="right">R$ { parseFloat(report['expense']).toFixed(2) }</TableCell>
																	<TableCell align="right">R$ { parseFloat(report['balance']).toFixed(2) }</TableCell>
																</TableRow>
															))}
														</TableBody>
													</Table>
												</Grid>
											</Grid>
										)} 
									</div>
								}
							</div>
						)					
						||
							<Grid item>
								<Alert variant="filled" severity="error">Comportamento inesperado. Tente novamente mais tarde.</Alert>
							</Grid>
						}
						
					</Grid>
				</Grid>			
			</Paper>
		</Container>
	);
}

export default Reports;