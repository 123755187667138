import React, { useEffect, useState, useCallback } from 'react';
import { BrowserRouter } from "react-router-dom";
import Header from './components/Header';
import HeaderAuth from './components/HeaderAuth';
import { createMuiTheme, CssBaseline, makeStyles, Theme, createStyles } from '@material-ui/core';
import { ThemeProvider } from "@material-ui/styles";
import moment from 'moment-timezone';
import './App.css';
import { isAuthenticated, getUser } from './services/auth';
import { User } from './interfaces';
import Routes from './routes';

moment.tz.setDefault("America/Sao_Paulo");

moment.locale("pt-br");

const theme = createMuiTheme({
	palette: {
		type: "dark",
		background: {
			default: "#1c2025",
			paper: "#282c33"
		},
		primary: { main: '#42A5F5' },
  		secondary: { main: '#69F0AE' }
	}
});

const useStyles = makeStyles((theme: Theme) => createStyles({
	root: { display: 'flex', },
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	},
}));

const App = () => {
	const classes = useStyles();
	const [user, setUser] = useState<User>({
		id: 0,
		birthday: "",
		cpf: "",
		createdAt: "",
		email: "",
		emailVerifiedAt: "",
		name: "",
		updatedAt: ""
	});
	const [authLoaded, setAuthLoaded] = useState(false);

	const checkTokenValid = useCallback(async () => {
		await isAuthenticated();
		setUser(getUser());
		setAuthLoaded(true);
	}, []);

	useEffect(() => {
		checkTokenValid();
	}, [checkTokenValid]);

	// Força a renderização apenas se já há informação do usuário
	if(authLoaded){
		if ((user) && (user.id !== 0)){
			return (
				<BrowserRouter>
					<ThemeProvider theme = {theme}>
						<CssBaseline />
						<div className={classes.root}>
							<HeaderAuth {... user} />
							<main className={classes.content}>
								<div className={classes.toolbar} />
								<div className="App">
									<Routes {... user}/>
								</div>
							</main>
						</div>
					</ThemeProvider>
				</BrowserRouter>
			);
		} else{
			return (
				<BrowserRouter>
					<ThemeProvider theme = {theme}>
						<CssBaseline />
						<div className={classes.root}>
							<Header />
							<main className={classes.content}>
								<div className={classes.toolbar} />
								<div className="App">
									<Routes {... user} />
								</div>
							</main>
						</div>
					</ThemeProvider>
				</BrowserRouter>
			);
		}
	}

	// Gambiarra
	return (
		<div>

		</div>
	);
}

export default App;
