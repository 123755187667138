import React, { useState, useEffect, useCallback } from "react";
import { Grid, Paper, IconButton, Typography, Container, Box, Snackbar, Modal, Backdrop, Fade, TextField, Button, createStyles, Theme } from "@material-ui/core";
import { getUser, getToken } from '../../services/auth';
import api from '../../services/api';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

// Icons
import ErrorIcon from '@material-ui/icons/Error';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { Delete, Save, Cancel } from "@material-ui/icons";
import MaterialTable from "material-table";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles((theme: Theme) => createStyles({
	table: {},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		maxWidth: '600px',
		margin: 'auto',
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[4],
		padding: theme.spacing(2, 4, 3),
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	  },
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	margin: {
		margin: theme.spacing(1),
	},
	groupButtons: {
		'& > *': {
			marginRight: theme.spacing(2),
		},
	},
	m0: {
		margin: '0px',
	},
	p0: {
		padding: '0px',
	},
	alignCenter: {
		textAlign: 'center'
	}
}));

const Accounts = (user) => {
	const [accounts, setAccounts] = useState([]);
	const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);
	const [isErrorLoadingAccounts, setIsErrorLoadingAccounts] = useState(false);
	const classes = useStyles();
	const [error, setError] = useState("");

	// Account
	const [id, setId] = useState(0);
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");

	// Modal & Snackbar
	const [operationType, setOperationType] = useState("");
	const [modalOpen, setModalOpen] = useState(false);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarText, setSnackbarText] = useState("");
	const handleModalClose = () => setModalOpen(false);

	const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === 'clickaway') {
		  return;
		}
	
		setSnackbarOpen(false);
	};

	const fetchAccounts = useCallback(async () => {
		setIsLoadingAccounts(true);
		setIsErrorLoadingAccounts(false);
		try{
			const response = await api.post("accounts/all", {
				'userToken': getToken(),
				'userID': user.id
			});
			const data = await response.data;

			setAccounts(data);
			setIsLoadingAccounts(false);
		} catch (err) {
			setIsErrorLoadingAccounts(true);
		}
	}, [user.id]);

	const newAccount = () => {
		setOperationType("new");
		setName("");
		setDescription("");
		setModalOpen(true);
	};

	const editAccount = (account) => {
		setOperationType("edit");
		setId(account.id);
		setName(account.name);
		setDescription(account.description);
		setModalOpen(true);
	};

	const handleCreateAccount = async e => {
		e.preventDefault();
		if (!name || !description){
			setError("Preencha todos os campos para criar esta conta!");
		}
		else{
			try{
				await api.post("/accounts/store", {
					'name': name,
					'description': description,
					'user_id': user.id
				});
				handleModalClose();
				fetchAccounts();
				setSnackbarOpen(true);
				setSnackbarText("Conta criada com sucesso!");
				setError("");
			} catch (e) {
				setError("Ocorreu um erro ao criar esta conta.");
			}
		}
	};

	const handleEditAccount = async e => {
		e.preventDefault();
		if (!id || !name || !description){
			setError("Preencha todos os campos para editar esta conta!");
		}
		else{
			try{
				await api.post("/accounts/update", {
					'id': id,
					'name': name,
					'description': description,
					'user_id': user.id
				});
				handleModalClose();
				fetchAccounts();
				setSnackbarOpen(true);
				setSnackbarText("Conta editada com sucesso!");
				setError("");
			} catch (e) {
				setError("Ocorreu um erro ao editar esta conta.");
			}
		}
	};

	const handleDeleteAccount = async e => {
		if (id !== 0){
			try {
				await api.post("/accounts/destroy", {
					'id': id,
					'user': getUser(),
					'token': getToken()
				});
				handleModalClose();
				fetchAccounts();
				setSnackbarOpen(true);
				setSnackbarText("Conta removida!");
			} catch (e) {
				setError("Ocorreu um erro ao remover esta conta.")
			}
		}
	};

	useEffect(() => {
		fetchAccounts();
	}, [fetchAccounts]);

	return (
		<Container maxWidth="md">
			<Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
				<Alert onClose={handleSnackbarClose} severity="success" variant={"filled"}>
					{snackbarText}
				</Alert>
			</Snackbar>

			<Modal
				className={classes.modal}
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={modalOpen}
				onClose={handleModalClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500
				}}
			>
				<Fade
					in={modalOpen}
				>
					<div className={classes.paper}>
						<form 
							onSubmit={(operationType === 'edit' ? handleEditAccount : handleCreateAccount)} 
							noValidate 
							autoComplete="off"
						>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Typography variant="h5" component="h5">
										{ operationType === 'edit' ? 'Editar conta' : 'Nova conta'}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									{error && 
									<Alert severity="error" variant="filled">
										<AlertTitle>Erro!</AlertTitle>
										<strong>{error}</strong>
									</Alert>}
								</Grid>
								<Grid item xs={12}>
									<TextField 
										label="Nome"
										placeholder="Digite o nome da conta"
										variant="outlined"
										fullWidth
										required
										value={name}
										onChange={e => setName(e.target.value)}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField 
										label="Descrição"
										placeholder="Digite a descrição"
										variant="outlined"
										fullWidth
										required
										value={description}
										onChange={e => setDescription(e.target.value)}
									/>
								</Grid>
								<Grid item xs={12}>
									<div className={classes.groupButtons}>
										{(operationType === 'edit' ? 
											<Button variant="outlined" color="default" onClick={handleDeleteAccount} startIcon={<Delete />}>Remover</Button>
											: ''
										)}
										<Button variant="outlined" color="secondary" onClick={handleModalClose} startIcon={<Cancel />}>Cancelar</Button>
										<Button variant="outlined" color="primary" type="submit" startIcon={<Save />}>Salvar</Button>
									</div>
								</Grid>
							</Grid>
						</form>
					</div>
				</Fade>
			</Modal>

			<Box component={Paper} p={2}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography>
							<b>Contas</b>
							<IconButton aria-label="add" onClick={newAccount}>
								<AddCircleIcon fontSize="large"/>
							</IconButton>
						</Typography>
					</Grid>
					{isErrorLoadingAccounts && 
						<Grid item xs={12}>
							<Alert variant="filled" severity="error">Erro ao carregar a lista de contas. Tente novamente mais tarde.</Alert>
						</Grid>
					}
					{(!isErrorLoadingAccounts && isLoadingAccounts) && 
						<Grid item xs={12}>
							<Paper>
								<Skeleton animation="wave" height={60} />
								<Skeleton animation="wave" height={60} />
								<Skeleton animation="wave" height={60} />
							</Paper>
						</Grid>
					}	
					{(!isErrorLoadingAccounts && !isLoadingAccounts) && 
						<Grid item xs={12}>
							{accounts.length === 0 ? (
								<Typography align="center">
									<ErrorIcon fontSize="large" />
									<br></br>
									Nenhuma conta cadastrada.
								</Typography>
							) : (
								<MaterialTable
									title=""
									columns={[
										{ title:'Nome', field: 'name' },
										{ title:'Descrição', field: 'description' },
										{ title:'Número de Lançamentos', field: 'transactions_count' },
										{ title:'Saldo', field: 'balanceBR' }
									]}
									data={accounts.map((account) => (
										{
											'id': account['id'],
											'name': account['name'],
											'description': account['description'],
											'transactions_count': account['transactions_count'],
											'balanceBR': "R$ " + (parseFloat(account['balance']).toFixed(2))
										}
									))}
									actions={[
										{
											icon: 'edit',
											tooltip: 'Editar conta',
											onClick: (event, rowData) => editAccount(rowData) 
										}
									]}
									options={{
										sorting: false,
										search: false,
										padding: 'dense',
										draggable: false,
										paging: false
									}}
								/>
								// <TableContainer component={Paper}>
								// 	<Table className={classes.table} size="small" aria-label="a dense table">
								// 		<TableHead>
								// 			<TableRow>
								// 				<TableCell align="center">Ações</TableCell>
								// 				<TableCell align="center">Nome da conta</TableCell>
								// 				<TableCell align="center">Descrição</TableCell>
								// 				<TableCell align="center">Número de Lançamentos</TableCell>
								// 				<TableCell align="center">Saldo</TableCell>
								// 			</TableRow>
								// 		</TableHead>
								// 		<TableBody>
								// 			{accounts.map((account) => (
								// 				<TableRow hover key={account['name']}>
								// 					<TableCell align="center">
								// 						<IconButton aria-label="edit" onClick={e => editAccount(e)}>
								// 							<EditIcon />
								// 						</IconButton>
								// 					</TableCell>
								// 					<TableCell align="center" component="th" scope="row">
								// 						{account['name']}
								// 					</TableCell>
								// 					<TableCell align="center">{account['description']}</TableCell>
								// 					<TableCell align="center">{account['transactions_count']}</TableCell>
								// 					<TableCell align="center">R$ { parseFloat(account['balance']).toFixed(2) }</TableCell>
								// 				</TableRow>
								// 			))}
								// 		</TableBody>
								// 	</Table>
								// </TableContainer>
							)}	
						</Grid>
					}
				</Grid>
			</Box>
		</Container>
	);
}

export default Accounts;