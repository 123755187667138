import api from './api';
import { User } from '../interfaces/index';

export const TOKEN_KEY = "@TCC-Token";

export const USER_KEY = "@TCC-User";

export const isAuthenticated = async () => await isTokenValid() !== false;

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const getUser = () => {
	const userTemp = JSON.parse(localStorage.getItem(USER_KEY) || "{}");
	const user: User = {
		id: userTemp['id'] || 0,
		name: userTemp['name'] || "",
		email: userTemp['email'] || "",
		emailVerifiedAt: userTemp['email_verified_at'] || "",
		createdAt: userTemp['created_at'] || "",
		updatedAt: userTemp['updated_at'] || "",
		cpf: userTemp['cpf'] || "",
		birthday: userTemp['birthday'] || ""
	};		
	return user;
};

export const login = (token, user) => {
	localStorage.setItem(TOKEN_KEY, token);
	localStorage.setItem(USER_KEY, JSON.stringify(user));
};
export const logout = () => {
	localStorage.removeItem(TOKEN_KEY);
	localStorage.removeItem(USER_KEY);
};

export const isTokenValid = async () => {
	if (getToken() !== null){
		api.get('token/verify', {
			headers: {
				Authorization: `Bearer ${getToken()}`
			}
		}).then(response => {
			const data = response;
			if (data.data.status === "Token is Invalid." || data.data.status === "Token is Expired."  || data.data.status === "Authorization Token not found."){
				// console.log("Problema com Token.");
				logout();
				return false;
			} else{
				localStorage.setItem(USER_KEY, JSON.stringify(data.data.user));
				// console.log("Local Storage salvo.");
				return true;
			}
		}).catch(error => {
			// console.log("Erro!");
			// console.log(error);
			return false;
		});
	} else{
		// console.log("Não tem Token salvo");
		return false;
	}
}