export const datetimeToHumanBrDate = (date:string) => {
	const readable = new Date(date);
	return readable.getDate() + "/" + (readable.getMonth() + 1) + "/" + readable.getFullYear();
};

export const datetimeToHumanBrDatetime = (date: string) => {
	const readable = new Date(date);
	return readable.getDate() + "/" + (readable.getMonth() + 1) + "/" + readable.getFullYear() + " às " + readable.getHours();
}

export const datetimeToJavascript = (date: string) => {
	const t = date.split(/[- :]/);
	return new Date(Date.UTC(Number(t[0]), Number(t[1])-1, Number(t[2]), Number(t[3]), Number(t[4]), Number(t[5])));
};